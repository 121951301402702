import axios from "axios";

export default {
    async getProdotti() {
        const data = new FormData();
        data.append("orderby", "fw_products_name");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/fw_products", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },

    async searchProductByBarcode(barcode) {
        const data = new FormData();
        data.append("where[]", "(fw_products_barcode LIKE '%" + barcode + "%' OR fw_products_sku LIKE '%" + barcode + "%')");
        data.append("orderby", "fw_products_name");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/fw_products", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

   /*  async getProdottiCommessa(projectId: string) {
        const data = new FormData();
        data.append("where[]", `fw_products_id IN (SELECT fw_products_id FROM rel_project_prod WHERE projects_id = '${projectId}')`);
    
        const response = await axios.post("rest/v1/search/fw_products", data, {
          headers: {
            Authorization: process.env.VUE_APP_TOKEN,
          },
        });
        //console.log(response);
        return response;
    }, */

    async getProdottiCommessa(projectId: string) {
        const data = new FormData();

        data.append("where[]", `project_products_project = '${projectId}'`);

        const response = await axios.post(`rest/v1/search/project_products`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response;
    },

    async getFilteredServizi(projectId: string, dayNumber: string) {
        const data = new FormData();
        data.append("where[]", `gestione_servizi_commessa_id = '${projectId}'`);
        data.append("where[]", `gestione_servizi_giorno_id = '${dayNumber}'`);
    
        const response = await axios.post(`rest/v1/search/gestione_servizi`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response;
    },

    async getProductDocument(prod: string) {
        const data = new FormData();
        data.append("where[]", `fw_product_documents_product = '${prod}'`);

        const response = await axios.post(`rest/v1/search/fw_product_documents`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response;
    },
    
};
